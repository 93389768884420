.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 1rem;
    padding: 1rem;
    animation: popIn ease-in 0.3s;
    transition: 1s;
    overflow: hidden;
    box-sizing: border-box;

    &_error {
      color: indianred;
      transition: 0.5s;
      animation: fadeIn ease-in 1s;
    }

    &_content {
      display: flex;
      flex-direction: column;

      > *:not(first-child) {
        margin-top: 0.5rem;
      }

      .login-input-row,
      .password-input-row {
        box-sizing: content-box;
        border-bottom: 1px solid #d4d4d4;

        label {
          color: #a4a4a4;
        }

        input {
          width: 100%;
          border: none;
          background-color: transparent;
          padding: 0.5rem;
        }

        .row {
          &:focus-within {
            background-color: #f4f4f4;
          }
        }
      }

      .material-icons {
        font-size: 16px;
        color: #d4d4d4;

        &:hover {
          color: #555;
        }

        transition: 0.5s;
      }

      .login-button {
        background-color: #83cdad;
        color: white;
        padding: 0.5rem;
        border-radius: 0.2rem;
        transition: 0.5s;
      }
    }
  }
}
