.symptomes-panel {
  --gap: 0.5em;

  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap) - 0.5rem);

  > * {
    margin: var(--gap) 0 0 var(--gap);
  }

  .material-icons {
    font-size: 14px;
  }

  input,
  .symptome-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 500px;
    padding: 5px;
    cursor: pointer;

    .material-icons {
      color: #a4a4a4;
    }

    &:hover {
      background-color: var(--light-grey);
    }
  }
}
