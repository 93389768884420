.symptome-suggester {
  position: relative;

  .material-icons {
    color: #83cdad;
  }

  form {
    border: 1px solid lightgray !important;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      animation: popIn ease-in 0.4s;
    }
  }

  &_input {
    border: none !important;
    padding: 5px;
  }

  &_items {
    position: absolute;
    width: 100%;
    background-color: white;
    max-height: 150px;
    overflow: auto;
    border-left: 1px solid var(--grey);
    border-right: 1px solid var(--grey);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation: fadeIn ease-in 0.2s;
  }

  &_item {
    display: block;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #d4d4d4;

    &:hover,
    &:focus {
      background-color: #f4f4f4;
    }
  }
}
