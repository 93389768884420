@import 'helpers/flexbox';
@import 'helpers/spacing';
@import 'helpers/colors';
@import 'helpers/typo';

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.row,
.column {
  display: flex;
}

.column {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row;

  &.gapped {
    gap: 0.5rem;
  }
}

.clickable {
  cursor: pointer !important;
}
