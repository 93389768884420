.drop-zone {
  box-sizing: border-box;
  max-height: 100%;
  display: grid;
  grid-template-rows: auto 200px 1fr;
  overflow: hidden;
}

.drop-zone p {
  color: red;
  text-align: center;
}

.drop-zone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 100%;
  border: 1px dashed #d4d4d4;
  cursor: pointer;

  &:hover {
    background-color: #f4f4f4;
    border-color: #a4a4a4;
  }
}

.drop-message {
  text-align: center;
  color: #747474;
  font-size: 20px;
}

.file-display-container {
  box-sizing: border-box;
  padding: 0 1rem;
  max-height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a4a4;
    border-radius: 15px;
  }
}

.file-status-bar {
  box-sizing: content-box;
  width: 100%;
  border-bottom: 1px solid #d4d4d4;
  animation: fadeIn ease 1s;

  &_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #566066;
  text-transform: uppercase;
}

.file-name {
  color: #747474;
}

.file-error {
  margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  margin-right: 5px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-upload-bar {
  transition: 0.5s;
  width: 0;
  height: 5px;
  background-color: #83cdad;
}

.file-remove {
  cursor: pointer;
  color: red;
}

.file-input {
  display: none;
}

.upload-button {
  padding: 0.5rem 1rem;
  border: 1px solid #a4a4a4;
  border-radius: 0.5rem;
}

.media {
  &-gallery {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    border-top: 1px solid #d4d4d4;
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-column-gap: 1rem;
    box-sizing: border-box;
  }

  &-viewer {
    height: 100%;
  }

  &-list {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;

    &_content {
      max-height: 100%;
      overflow: auto;
      border-right: 1px solid #d4d4d4;
    }

    &_item {
      cursor: pointer;
      display: grid;
      grid-template-columns: 50px 1fr auto;
      grid-column-gap: 1rem;
      align-items: center;
      height: 50px;
      margin-bottom: 0.4rem;

      img {
        background-position: center;
        background-repeat: unset;
        background-size: cover;
        width: 100%;
        height: 100%;
      }

      transition: 200ms;

      &:hover,
      &:focus {
        background-color: #f4f4f4;
      }

      animation: fadeIn ease 1s;
    }
  }
}
