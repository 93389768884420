$spaceamounts: (0, 0.25, 0.5, 0.75, 1, 1.5, 3); // in rem
$sides: (top, bottom, left, right);

$i: 0;

/**
 * Create margin and padding helpers such as mt-2 = margin-top: 0.5rem.
 */
@each $space in $spaceamounts {
  .m-#{$i} {
    margin: #{$space}rem;
  }
  .p-#{$i} {
    padding: #{$space}rem;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$i} {
      margin-#{$side}: #{$space}rem;
    }
    .mx-#{$i} {
      margin-left: #{$space}rem;
      margin-right: #{$space}rem;
    }
    .my-#{$i} {
      margin-top: #{$space}rem;
      margin-bottom: #{$space}rem;
    }
    .p#{str-slice($side, 0, 1)}-#{$i} {
      padding-#{$side}: #{$space}rem;
    }
    .px-#{$i} {
      padding-left: #{$space}rem;
      padding-right: #{$space}rem;
    }
    .py-#{$i} {
      padding-top: #{$space}rem;
      padding-bottom: #{$space}rem;
    }
  }
  $i: $i + 1;
}

.push-left {
  margin-left: auto;
}

.push-right {
  margin-right: auto;
}
