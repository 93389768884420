.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.text-xs {
  font-size: 0.6rem;
}

.text-sm {
  font-size: 0.8rem;
}

.text-1 {
  font-size: 1rem;
}

.text-2 {
  font-size: 1.2em;
}
