.input-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding-left: 0.25rem;
  margin-left: 3rem;
  margin-right: auto;
  border: 1px solid #d4d4d4;
  border-radius: 1rem;
  transition: border 0.2s ease;

  > * {
    z-index: 3;
  }

  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .material-icons {
    font-size: 1.4rem;
    color: #555;

    &.search-icon {
      display: flex;
      align-items: center;
      height: inherit;
      border-right: 1px dashed #d4d4d4;
    }
  }

  > input {
    border: none;
    font-size: 1rem;

    &.input-main-plainte {
      width: 350px;
    }

    &.input-secondary-plainte {
      width: 150px;
      border-left: 1px dashed #d4d4d4;

      &:focus {
        width: 350px;
      }
    }
  }

  .clear-icon-wrapper {
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    margin-left: 0.5rem;
    border-left: 1px dashed #d4d4d4;

    &:hover {
      .material-icons {
        color: var(--dark-grey);
      }
    }
  }
}

.consultation-container {
  position: absolute;
  top: 1rem;
  left: -0.1rem;
  transform: scaleY(0);
  transform-origin: top;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 75vh;
  overflow: hidden;
  margin-top: 1.25rem;
  border-top: 1px dashed #d4d4d4;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: var(--white);
  box-shadow: 0 0.2rem 0.25rem rgba(black, 0.075);
  z-index: 2;
  transition: transform 0.2s ease;

  &.expanded {
    transform: scaleY(1);
  }

  .consultations-container-content {
    overflow: auto;

    .consultation {
      display: grid;
      grid-template-columns: 710px 90px;
      align-items: center;
      padding: 0.25rem 0.5rem;

      .secondary-plainte {
        font-size: 0.9rem;
        color: var(--dark-grey);
      }

      .highlight {
        background-color: var(--grey);
      }

      .client-date {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        color: var(--dark-grey);
        font-style: italic;

        span:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span:last-child {
          align-self: flex-end;
        }
      }

      &:nth-child(even) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: var(--light-grey);
      }
    }
  }

  .consultations-container-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    height: 25px;
    font-size: 0.9rem;
    color: var(--dark-grey);
    border-top: 1px dashed #d4d4d4;
  }
}
