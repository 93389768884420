.white {
  color: var(--white);
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.grey {
  color: var(--grey);
}

.dgrey {
  color: var(--dark-grey);
}

.b-white {
  background-color: var(--white);
}

.b-lgrey {
  background-color: var(--light-grey);
}

.b-grey {
  background-color: var(--grey);
}

.b-dgrey {
  background-color: var(--dark-grey);
}

.b-green {
  background-color: var(--green);
}
