button {
  display: block;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 1rem;
  color: #282c34;

  &:disabled {
    background-color: #f4f4f4;
    color: #a4a4a4;
    border-color: #d4d4d4;
  }

  /* Save button */
  &.save-button {
    border: 1px solid var(--green);
    border-radius: 5px;
    padding: 0.5rem;
    background-color: var(--green);
    color: white;

    &:hover {
      background-color: var(--white);
      color: var(--green);
    }

    transition: 500ms;
  }

  /* Default button */
  &.btn-default {
    background-color: var(--grey);
    padding: 0.5em;
    border-radius: 5px;
  }

  /* Danger button */
  &.btn-danger {
    color: var(--white);
    background-color: var(--light-red);
    border: 1px solid var(--light-red);
    padding: 0.5em;
    border-radius: 5px;

    &:hover {
      background-color: var(--red);
      border-color: var(--red);
    }
  }

  &.btn-danger-outline {
    color: var(--light-red);
    background-color: var(--white);
    padding: 0.5em;
    border: 1px solid var(--light-red);
    border-radius: 5px;

    &:hover {
      color: var(--red);
      border-color: var(--red);
    }
  }
}
