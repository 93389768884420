.client-consultation-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  padding-right: 0.25rem;
  width: 100%;
  overflow: auto;

  label {
    align-self: flex-start;
  }

  .client-consultation-list-header {
    position: absolute;
    top: 0;
    left: 0;
  }

  .consultation-list_item {
    border: 1px solid #d4d4d4;
    background-color: white;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 1rem;
    animation: fadeIn ease-in 0.5s;
    width: 100%;

    &.selected,
    &:hover,
    &:focus {
      background-color: #83cdad;
      color: white;
      border-color: transparent;
      transition: 0.5s;
    }
  }

  button.add-consultation-btn {
    border-radius: 50%;
    line-height: 0;
    padding: 2px;

    .material-icons {
      font-size: 1.2em;
      margin: 0;
    }

    &:hover {
      background-color: #83cdad;
      color: white;
      border-color: transparent;
      transition: 0.5s;
    }
  }
}
