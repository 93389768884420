.client-search-bar {
  display: grid;
  grid-template-columns: auto 1fr 2fr 2fr auto;
  align-items: center;
  padding: 0 0.2rem;

  .material-icons {
    font-size: 14px;
    color: #555;
  }

  input {
    font-size: 16px;
    padding: 0.4rem 0.5rem;
    border: none;
    border-left: 1px dashed #d4d4d4;
    width: 100%;
    box-sizing: border-box;

    &:focus {
      background-color: #f4f4f4;
    }
  }

  button {
    color: #83cdad;
    border-radius: 50%;
    padding: 5px;
    line-height: 0;

    &:focus,
    &:hover {
      background-color: var(--green);

      .material-icons {
        color: var(--white);
      }
    }
  }
}
