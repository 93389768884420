.DateRangeSelect {
  display: inline-flex;
  align-items: center;

  input {
    max-width: 115px;
    border: none;
    border-left: 1px dashed #d4d4d4;
  }

  ::-webkit-calendar-picker-indicator {
    margin-left: 0;
  }
}
