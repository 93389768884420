@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import 'styles/variables';
@import 'styles/helpers';
@import 'styles/button';
@import 'styles/form';

:root {
  --white: #{$white};
  --light-grey: #{$light-grey};
  --grey: #{$grey};
  --dark-grey: #{$dark-grey};
  --light-red: #{$light-red};
  --red: #{$red};
  --dark-red: #{$dark-red};
  --light-green: #{$light-green};
  --green: #{$green};
  --dark-green: #{$dark-green};
  --text-color: #{$text-color};
  --bg-color: #{$bg-color};
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--bg-color);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  max-width: 100%;
  overflow: hidden;
  min-height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(400px, 2fr) auto 8fr;

  > * {
    height: 100%;
    box-sizing: border-box;
  }
}

.forme,
.mode,
.posologie {
  &-suggester {
    display: flex;
    position: relative;

    form {
      display: flex;
      width: 100%;

      button {
        .material-icons {
          font-size: 16px;
        }

        &:focus {
          color: #83cdad;
        }
      }
    }

    input {
      width: 100%;
    }
  }

  &-suggestions {
    position: fixed;
    background-color: white;
    z-index: 999;
    display: flex;
    flex-direction: column-reverse;
    animation: popIn 0.2s ease-in;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
  }

  &-suggestion {
    width: 100%;
    text-align: left;

    &:focus {
      background-color: #d4d4d4;
    }
  }
}

@keyframes popIn {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #a4a4a4;
  border-radius: 15px;
}
