.toolbar {
  position: inherit;
}

.wrapper {
  height: 100%;
  overflow: hidden;
}

.editor {
  max-height: 80%;
}

@media screen and (min-width: 1695px) {
  .editor {
    max-height: 90%;
  }
}
