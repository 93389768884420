.loader-wrapper {
  display: grid;
  place-items: center;
  height: 100%;

  > div {
    display: flex;
  }

  &.full-width {
    width: 100%;
  }
}
