textarea,
input {
  padding: 2px 0 2px 5px;
  color: #555;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  border: 1px solid transparent;
  border-bottom-color: lightgray;
  transition: 0.4s;

  &:hover,
  &:focus {
    outline: none;
  }
}

textarea {
  resize: none;
  border: 1px solid #d4d4d4;
  font-family: inherit;
}

label {
  font-size: 0.8rem;
  padding: 2px 10px;
  color: #848484;

  &.required::after {
    content: '*';
    color: var(--red);
  }
}

.textarea-row,
.input-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
