.consultation-panel {
  max-height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto auto auto 1fr;
  grid-row-gap: 1rem;
  padding-left: 1rem;
  box-sizing: border-box;
  position: relative;

  .del-consultation-btn:hover {
    color: var(--red);
  }

  input {
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 1rem;
    transition: 0.4s;
  }

  &_header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 1rem;
    align-items: center;

    input {
      border-radius: 1rem;
      border: 1px solid #dadada;
      background-color: white;
      padding: 0.5rem;
    }
  }

  .formule-input {
    input {
      border-radius: 1rem;
      border: 1px solid #dadada;
      background-color: white;
      padding: 0.5rem;
    }
  }

  &_buttons {
    display: flex;
    align-items: center;
    font-size: 12px;

    > * {
      margin-left: 0.5rem;
    }

    button {
      font-weight: 300;
      color: #a4a4a4;
      transition: 0.4s;

      &.selected {
        font-size: 16px;
        color: #83cdad;
        border-bottom: 1px solid #83cdad;
      }

      &:hover,
      &:focus {
        color: #83cdad;
      }
    }
  }

  &_info {
    height: 100%;
    overflow: hidden;
    border-top: 1px solid #d4d4d4;
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-column-gap: 1rem;

    > * {
      display: grid;
      grid-template-rows: auto 1fr;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    .notes {
      label {
        border-radius: 50px;
        border: 1px solid transparent;
        transition: 0.4s;
        cursor: pointer;

        &.selected {
          border-color: #d4d4d4;
        }
      }
    }

    textarea {
      max-height: 100%;
      resize: unset;
      border: 1px solid transparent;
      background-color: transparent;
      background-attachment: local;
      background-image: linear-gradient(
          to right,
          transparent 10px,
          transparent 10px
        ),
        linear-gradient(to left, transparent 10px, transparent 10px),
        repeating-linear-gradient(
          transparent,
          transparent 30px,
          #f4f4f4 30px,
          #f4f4f4 31px,
          transparent 31px
        );
      line-height: 31px;
      padding: 8px 10px;
      border-radius: 6px;
      transition: 0.5s;

      &:focus {
        background-image: linear-gradient(
            to right,
            transparent 10px,
            transparent 10px
          ),
          linear-gradient(to left, transparent 10px, transparent 10px),
          repeating-linear-gradient(
            transparent,
            transparent 30px,
            #d4d4d4 30px,
            #d4d4d4 31px,
            transparent 31px
          );
      }

      &:focus-within {
        background-color: #f4f4f4;
      }
    }

    .preparation {
      max-height: 100%;
      overflow: hidden;

      .input-row {
        &:focus-within {
          label {
            text-decoration: underline;
          }

          background-color: #f4f4f4;
        }
      }

      &-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .material-icons {
          font-size: 14px;
        }
      }

      &-button {
        border-radius: 50px;
        margin-left: 5px;
        background-color: #f4f4f4;
        color: #555;

        &:focus,
        &:hover {
          background-color: #d4d4d4;
        }

        &.selected {
          background-color: #83cdad;
          color: white;
        }
      }

      &_ingredient {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto 1fr;
        max-height: 100%;
        overflow: auto;

        &:focus-within {
          background-color: #f4f4f4;
        }

        .line_number {
          padding: 0 2px;
          text-align: center;
          font-size: 8px;
          line-height: 21px;
          color: #a4a4a4;
        }

        textarea {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          overflow-y: hidden;
          padding-top: 0;
          background-image: linear-gradient(
              to right,
              transparent 10px,
              transparent 10px
            ),
            linear-gradient(to left, transparent 10px, transparent 10px),
            repeating-linear-gradient(
              transparent,
              transparent 20px,
              #f4f4f4 20px,
              #f4f4f4 21px,
              transparent 21px
            );
          line-height: 21px;

          &:focus {
            background-image: linear-gradient(
                to right,
                transparent 10px,
                transparent 10px
              ),
              linear-gradient(to left, transparent 10px, transparent 10px),
              repeating-linear-gradient(
                transparent,
                transparent 20px,
                #d4d4d4 20px,
                #d4d4d4 21px,
                transparent 21px
              );
          }
        }
      }

      &_content {
        max-height: 100%;
        overflow: hidden;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid #d4d4d4;
        margin-bottom: 5px;
        padding: 5px;
        display: grid;
        grid-template-rows: 1fr auto auto auto;
        background-color: white;

        > *:not(:last-child) {
          border-bottom: 1px solid #d4d4d4;
        }

        &:focus-within {
          border-color: #a4a4a4;
        }
      }

      &_info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        input {
          width: 100%;
        }
      }
    }
  }
}

.export-area {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  textarea {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
    padding: 0.5rem;
    resize: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 3px solid transparent;

    &:hover {
      background-color: #f4f4f4;
      border-color: #83cdad;
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
  }
}
