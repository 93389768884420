section.client-side {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 1rem;
  border-right: 1px solid #d4d4d4;
  padding-right: 1rem;
  max-height: 100%;
  overflow: hidden;
}

section.consultations {
  position: relative;
  display: flex;
  padding: 0 0.5rem;
  width: 150px;
  max-height: 100%;
  border-right: 1px solid lightgray;
  overflow: auto;
}
