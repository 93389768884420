header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d4;
  padding: 1rem;

  .logo {
    white-space: nowrap;

    &:hover {
      color: var(--dark-grey);
    }
  }

  .logout-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 0.5rem;

    .material-icons {
      font-size: 1rem;
    }
  }
}
