.client-form {
  padding-right: 0.5rem;
  background-color: var(--bg-color);
  animation: popIn ease-out 0.2s;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;

  .input-row {
    &:focus-within {
      label {
        text-decoration: underline;
      }

      background-color: #f4f4f4;
    }
  }

  .address-form {
    &_content {
      border-left: 1px dashed #d4d4d4;
      padding: 0.5rem;
      margin-left: 1rem;

      label {
        font-size: 0.6rem;
      }
    }
  }
}
