.clients-panel {
  max-height: 100%;
  display: grid;
  position: relative;
  height: 2rem;

  &_content {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #d4d4d4;
    border-radius: 1rem;

    &:focus-within {
      border-color: #a4a4a4;
    }
  }

  .clients-list {
    width: 100%;

    &_item {
      width: 100%;
      text-align: left;
      padding: 0.5rem;
      border-bottom: 1px solid #ddd;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:focus,
      &:hover {
        outline: none;
        background-color: #f4f4f4;
      }
    }
  }
}
